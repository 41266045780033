import React, { useState, useEffect } from "react";
import ApiContext from "../../Context/ApiContext";
import { toast } from "react-toastify";

const DocumentUploadForm = () => {
  const { studentDocumentdata, studentDetail, documentUploadMeathod, url } = React.useContext(ApiContext);
  const [filePreviews, setFilePreviews] = useState({});
  const [files, setFiles] = useState({});
  const maxFileSize = 200 * 1024; // 200KB in bytes

  // Set initial previews if documents already exist on the server
  useEffect(() => {
    if (studentDocumentdata) {
      const initialPreviews = {
        aadhar: `${url}${studentDocumentdata.aadhar?.url}`,
        pan: `${url}${studentDocumentdata.pan?.url}`,
        Intermediate: `${url}${studentDocumentdata.Intermediate?.url}`,
        Matriculation: `${url}${studentDocumentdata.Matriculation?.url}`,
        Migration: `${url}${studentDocumentdata.Migration?.url}`,
        Others: `${url}${studentDocumentdata.Others?.url}`,
        Undertaking: `${url}${studentDocumentdata.Undertaking?.url}`,
      };
      setFilePreviews(initialPreviews);
      console.log(initialPreviews);
      
    }
  }, [studentDocumentdata]);

  const handleFileChange = (event, fieldName) => {
    const file = event.target.files[0];    
    

    if (file) {
      if (file.type !== "application/pdf") {
        alert("Only PDF files are allowed.");
        event.target.value = null;
        return;
      }
      if (file.size > maxFileSize) {
        alert("File size must be 200KB or less.");
        event.target.value = null;
        return;
      }

      // Set file preview
      const fileUrl = URL.createObjectURL(file);
      
      setFilePreviews((prevPreviews) => ({
        ...prevPreviews,
        [fieldName]: fileUrl,
      }));
      setFiles((prevFiles) => ({
        ...prevFiles,
        [fieldName]: file,
      }));

      
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("files", file, file.name);

    const response = await fetch(`${url}/api/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData,
    });

    const data = await response.json();
    return data[0].id; // Assuming response returns an ID for the uploaded file
  };

  const handleSubmission = async (event) => {
    event.preventDefault();

    try {
      const documentIds = {};

      for (const [fieldName, file] of Object.entries(files)) {
        const fileId = await uploadFile(file);
      
        
        documentIds[fieldName] = fileId;
      }
      const dataToSend = {
        data: {
          ...documentIds,
          student: { connect: [studentDetail.documentId] },
        },
      };
      
      // Link uploaded document IDs to the student record
      if(documentUploadMeathod === 1){
        const dataToSendPut = {
          data: {
            ...documentIds,
          },
        };
        const response = await fetch(
          `${url}/api/student-documents/${studentDetail?.student_document?.documentId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(dataToSendPut),
          }
        );
        if(response.ok) {toast.success("Document Updated Successfully") 
          setTimeout(() => window.history.back(), 1500); }
        return
      }
      // alert("not skipped")
      const response = await fetch(`${url}/api/student-documents`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(dataToSend),
      });
      if (response.ok) {toast.success("Document Updated Successfully")
        setTimeout(()=>window.history.back(), 1500) }
      const result = await response.json();
      console.log("Document association result:", result);
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  return (
    <div className="flex bg-gray-100 w-full justify-center py-8">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl">
        <h1 className="text-center text-2xl mb-8">
          Document Upload for{" "}
          <span className="font-bold underline capitalize">
            {studentDetail?.studentName}{}
          </span>
        </h1>
        <h2 className="text-center text-sm mb-4 underline font-semibold italic">
          Son of {studentDetail?.fatherName}. DOB {studentDetail?.dateOfBirth}
        </h2>

        <form onSubmit={handleSubmission}>
          {[
            { label: "Upload Aadhar", field: "aadhar" },
            { label: "Upload Pan", field: "pan" },
            { label: "Upload Intermediate", field: "Intermediate" },
            { label: "Upload Matriculation", field: "Matriculation" },
            { label: "Upload Migration", field: "Migration" },
            { label: "Upload Other", field: "Others" },
            { label: "Upload Undertaking", field: "Undertaking" },
          ].map(({ label, field }) => (
            <div key={field} className="flex flex-col md:flex-row w-full py-2">
              <div className="flex w-full md:w-1/2 justify-end px-4">
                <label htmlFor={field} className="text-gray-700">
                  {label}:
                </label>
              </div>
              <div className="flex w-full md:w-1/2">
                <input
                  type="file"
                  id={field}
                  name={field}
                  accept="application/pdf"
                  className="text-sm"
                  onChange={(e) => handleFileChange(e, field)}
                />
              </div>
              {(filePreviews[field] || studentDocumentdata?.[field]?.url) && (
                <div className="w-full mt-2 px-4 md:px-0">
                  <embed
                    src={
                      filePreviews[field] ||
                      `/uploads${studentDocumentdata?.[field]?.url}`
                    }
                    type="application/pdf"
                    className="w-full h-48 border border-gray-300 rounded-lg"
                  />
                  <p className="text-center text-xs text-gray-600 mt-1">
                    Preview of {label}
                  </p>
                </div>
              )}
            </div>
          ))}
          <div className="text-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-lg"
              type="submit"
            >
              Upload Document
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DocumentUploadForm;
