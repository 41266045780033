import { useEffect, useState } from "react";
import ApiContext from "./ApiContext";
import { toast } from "react-toastify";

const ApiState = (props) => {
  // const url = "https://loving-agreement-e6107db985.strapiapp.com";
  // const url = "http://localhost:1337";
  const url = "https://api.sgtu.co.in";
  

  const user = localStorage.getItem("user");
  const [SubCenterData, setSubCenterData] = useState([]);
  const [session, setSessions] = useState(null);
  const [courses, setCourses] = useState(null);
  const [token, setToken] = useState(null);
  
  
  const statesdata = [
    { name: "Andhra Pradesh" },
    { name: "Arunachal Pradesh" },
    { name: "Assam" },
    { name: "Bihar" },
    { name: "Chhattisgarh" },
    { name: "Goa" },
    { name: "Gujarat" },
    { name: "Haryana" },
    { name: "Himachal Pradesh" },
    { name: "Jharkhand" },
    { name: "Karnataka" },
    { name: "Kerala" },
    { name: "Madhya Pradesh" },
    { name: "Maharashtra" },
    { name: "Manipur" },
    { name: "Meghalaya" },
    { name: "Mizoram" },
    { name: "Nagaland" },
    { name: "Odisha" },
    { name: "Punjab" },
    { name: "Rajasthan" },
    { name: "Sikkim" },
    { name: "Tamil Nadu" },
    { name: "Telangana" },
    { name: "Tripura" },
    { name: "Uttar Pradesh" },
    { name: "Uttarakhand" },
    { name: "West Bengal" },
    { name: "Andaman and Nicobar Islands" },
    { name: "Chandigarh" },
    { name: "Dadra and Nagar Haveli and Daman and Diu" },
    { name: "Lakshadweep" },
    { name: "Delhi" },
    { name: "Puducherry" },
    { name: "Ladakh" },
    { name: "Jammu and Kashmir" },
  ];

  // Login Function
  const loginFunc = async (identifier, password) => {
    try {
      const response = await fetch(`${url}/api/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ identifier, password }),
      });

      return response;
    } catch (err) {
      toast.error("Internal Server Error");
      console.log(err);
    }
  };

  // Verify Token

  const VerifyToken = async (token) => {
    try {
      const response = await fetch(`${url}/api/users/me`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error verifying token:", error);
      return null; // Return null in case of an error
    }
  };

  // Session
  const Session = async () => {
    try {
      const response = await fetch(`${url}/api/sessions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setSessions(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Course
  const Course = async () => {
    try {
      const response = await fetch(`${url}/api/courses`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setCourses(data.data);
      // console.log(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [studentData, setStudentData] = useState(null);
  const fetchStudentData = async () => {
    try {
      const response = await fetch(
        `${url}/api/students?filters[studentOf][documentId][$eq]=${user}&populate=*`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      // console.log(data.data)
      setStudentData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [studentFromData, setStudentFromData] = useState(null);

  const printstudentForm = async (id) => {
    try {
      const response = await fetch(
        `${url}/api/students/${id}?populate=*`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setStudentFromData(data.data);  
    } catch (error) {
      console.log(error);
    }
  };

  const [studentDocumentdata, setStudentDocumentdata] = useState()
  const [documentUploadMeathod, setDocumentUploadMethod] = useState(0)
  const [studentDetail, setStudentDetials] = useState(null)

  const findStudentDocument = async (data) => {
    try {
      const id = data.student_document.documentId;
  
      // console.log(data);
      
      const response = await fetch(
        `${url}/api/student-documents/${id}?populate=*`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 404) {
        console.log("Document not found");
        setDocumentUploadMethod(0);
        return;
      }
      if (response.status === 200) {
        // console.log(response.status);
        
        setDocumentUploadMethod(1);
        const data1 = await response.json();
        setStudentDocumentdata(data1.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubCenterData = async () => {
    const response = await fetch(`${url}/api/informationSubCenter`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setSubCenterData(data);
  };

  useEffect(() => {
    // fetchSubCenterData();
    Session();
    Course();
    fetchStudentData();
  }, []);

  return (
    <ApiContext.Provider
      value={{
        url,
        SubCenterData,
        token,
        VerifyToken,
        loginFunc,
        session,
        statesdata,
        courses,
        user,
        studentData,
        fetchStudentData,
        printstudentForm,
        studentFromData,
        setToken,
        findStudentDocument,
        studentDocumentdata,
        documentUploadMeathod,
        setStudentDocumentdata,
        studentDetail,
            setStudentDetials
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};

export default ApiState;
