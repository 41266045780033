import React, { useContext, useState } from "react";
import logo from "../../Images/logo.png";
import ApiContext from "../../../Context/ApiContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminLogin = () => {
  const [identifier, setidentifier] = useState("");
  const [password, setPassword] = useState("");
  const { loginFunc, setToken } = useContext(ApiContext);
  const navigate = useNavigate();

  // Function to handle login

  const handleLogin = async () => {
    try {
      const response = await loginFunc(identifier, password);

      const data = await response.json();

      if (response.status === 200) {
        toast("Login Successful");
        setToken(data.jwt);
        localStorage.setItem("token", data.jwt);
        localStorage.setItem("user", data.user.documentId);
        setTimeout(() => {
          navigate("/auth/dashboard");
        }, 1000);
      }
      if (response.status === 400) {
        toast(data.error.message);
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-2/3 h-100 flex">
        <div className="absolute top-4 right-4">
          <ToastContainer />
        </div>
        <img src={logo} alt="" className="mr-16" />
        <div className=" w-full my-auto">
          <h2 className="text-4xl font-bold text-center mb-6">User Login</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                User ID
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter your user ID"
                value={identifier}
                onChange={(e) => setidentifier(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type="password"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <button
              type="submit"
              onClick={handleLogin}
              className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
