import React from 'react'
import Sidebar from '../Assets/Sidebar';
import Navbar from '../Assets/Navbar';
import PasswordUpdate from '../Assets/PasswordUpdate';



const ChangePassword = () => {
  return (
    <div>
      <Navbar />
      <div className="flex">
        <Sidebar />
        <PasswordUpdate/>
      </div>
    </div>
  );
}

export default ChangePassword