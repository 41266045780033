import React, { useContext, useState } from "react";
import SubCenterTable from "./Table/SubCenterTable";
import ApiContext from "../../Context/ApiContext";

const ManageSubCenterForm = () => {
  const { url, token } = useContext(ApiContext);

  const submitSubCenter = async(e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
      const response = await fetch(`${url}/api/auth/local/register`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          username: subCenterData.centerTitle,
          email: subCenterData.emailAddress,
          password: "securepassword",
          role: 4, // replace ROLE_ID with the actual ID of the role
        }),
      });
      const data = await response.json();
      if (response.status.ok) {
        alert("Sub Center Added Successfully");
      }
  }
  const [subCenterData, setSubCenterData] = useState({
    centerTitle: "",
    contactPerson: "",
    contactNo: "",
    emailAddress: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setSubCenterData({ ...subCenterData, [id]: value });
  };

  return (
    <div className="w-full bg-gray-200 box-border">
      <div className="m-4 bg-white p-2 h-full rounded-lg shadow-sm">
        {/* Starting of the section */}
        <h1 className="text-green-500 mx-4 py-2 font-semibold text-xl">
          Add New Sub Center
        </h1>
        <hr className="mx-4" />
        {/* Form Starts */}
        <div action="" className="mt-4 px-2">
          <div className="flex">
            {/* Left Section */}
            <div className="w-1/2">
              <div className="flex flex-col">
                <label htmlFor="name" className="p-1 font-semibold">
                  Center Title*
                </label>
                <input
                  type="text"
                  id="centerTitle"
                  value={subCenterData.centerTitle}
                  onChange={handleChange}
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                  placeholder="Center Title*"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="name" className="p-1 font-semibold">
                  Contact No*
                </label>
                <input
                  type="text"
                  id="contactNo"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                  placeholder="Contact No*"
                  value={subCenterData.contactNo}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="name" className="p-1 font-semibold">
                  Location*
                </label>
                <input
                  type="text"
                  id="location"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                  placeholder="Location*"
                  onChange={handleChange}
                  value={subCenterData.location}
                />
              </div>
            </div>
            {/* Right Section */}
            <div className="w-1/2">
              <div className="flex flex-col">
                <label htmlFor="name" className="p-1 font-semibold">
                  Contact Person*
                </label>
                <input
                  type="text"
                  id="contactPerson"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                  placeholder="Contact Person*"
                  value={subCenterData.contactPerson}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="name" className="p-1 font-semibold">
                  Email Address*
                </label>
                <input
                  type="text"
                  id="emailAddress"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                  placeholder="Email Address*"
                  onChange={handleChange}
                  value={subCenterData.emailAddress}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center my-4">
            <button
              className="text-white bg-blue-900 p-2 px-4 rounded"
              onClick={submitSubCenter}
            >
              Add Center
            </button>
          </div>
        </div>
        {/* Form Ends */}
        {/* Table */}
        <h1 className="text-green-500 mx-4 py-2 font-semibold text-xl">
          Sub Centers Summary
        </h1>
        <hr className="mx-4" />
        <div className="container box-border w-full max-w-screen-lg">
          <SubCenterTable />
        </div>
      </div>
    </div>
  );
};

export default ManageSubCenterForm;
