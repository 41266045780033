import React, { useContext } from "react";
import dashboadlogo from "../Images/dashboardlogo.png";
import ApiContext from "../../Context/ApiContext";
import { ToastContainer } from "react-toastify";

const Navbar = () => {
  const { name } = useContext(ApiContext);
  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  return (
    <div className="flex w-full px-2 shadow-lg bg-gradient-to-r from-blue-500 to-purple-600 ">
      <div className="flex-1 flex space-x-6 items-center">
        <div className="rounded">
          <img
            src={dashboadlogo}
            alt="Dashboard Logo"
            className="w-60 h-auto"
          />
        </div>
        <div className="text-white font-bold p-2 rounded text-3xl flex-col">
          <div>
            Welcome <b>{name}</b>
          </div>
          <div className="text-yellow-300">DASHBOARD</div>
        </div>
      </div>
      <div
        className="flex-none bg-red-600 hover:bg-red-700 my-5 transition duration-300 ease-in-out px-4 py-2 rounded-full ml-auto cursor-pointer flex items-center space-x-2"
        onClick={handleLogout}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M3 4a1 1 0 011-1h6a1 1 0 110 2H5v10h5a1 1 0 110 2H4a1 1 0 01-1-1V4zm10.293 4.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L15.586 13H9a1 1 0 110-2h6.586l-1.293-1.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
        <span className="text-white">Logout</span>
      </div>
      <div className="absolute top-4 right-4 text-sm text-black">
        <ToastContainer />
      </div>
    </div>
  );
};

export default Navbar;
