import React from "react";
import Navbar from "../Assets/Navbar";
import Sidebar from "../Assets/Sidebar";
import Home from "../Assets/Home";

const Dashboard = () => {
  return (
    <div>
      <Navbar />
      <div className="flex">
        <Sidebar />
        <Home />
      </div>
    </div>
  );
};

export default Dashboard;
