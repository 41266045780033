import React from 'react'

const Footer = () => {
  return (
    <div className="text-center py-4 bg-gray-300 w-full">
      © 2023-2024 Sikkim Global Technical University
    </div>
  );
}

export default Footer