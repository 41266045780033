import { useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom"; // Or 'next/router' for Next.js
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import ApiContext from "../../../Context/ApiContext";
import { useEffect } from "react";

const StudentTable = () => {
  const {
    studentData,
    fetchStudentData,
    printstudentForm,
    findStudentDocument,
    setStudentDocumentdata,
    setStudentDetials
  } = useContext(ApiContext);
  const navigate = useNavigate();

  const PrintingFunction = (id) => {
    printstudentForm(id);

    setTimeout(() => {
      navigate("/auth/printstudent");
    }, 1000);
  };
const UploadDocument = async (data) => {
  try {
    setStudentDocumentdata(null);
    setStudentDetials(data)
    await findStudentDocument(data);
    navigate("/auth/documentupload");
  } catch (error) {
    console.error("Error uploading document:", error);
  }
};


  useEffect(() => {
    fetchStudentData();
    setInterval(() => {
      fetchStudentData();
    }, 5000);
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "studentName",
        header: "Student Name",
        size: 150,
      },
      {
        accessorKey: "emailAddress",
        header: "Email Address",
        size: 200,
      },
      {
        accessorKey: "fee_status",
        header: "Fee Status",
        size: 100,
      },
      {
        accessorKey: "course.course_name",
        header: "Course",
        size: 100,
        Cell: ({ cell }) => cell.row.original.course?.course_name || "N/A",
      },
      {
        accessorKey: "session.Session",
        header: "Session",
        size: 150,
        Cell: ({ cell }) => cell.row.original.session?.Session || "N/A",
      },
      {
        id: "print", // Unique ID for this custom column
        header: "Print Form",
        size: 100,
        Cell: ({ row }) => (
          <button
            className="border border-blue-600 rounded-md px-4 py-1 bg-blue-500 text-white hover:bg-white hover:text-blue-600 transition duration-300 ease-in-out"
            onClick={() => PrintingFunction(row.original.documentId)}
          >
            Print
          </button>
        ),
      },
      {
        id: "DocumentUpload", // Unique ID for this custom column
        header: "Document",
        size: 100,
        Cell: ({ row }) => (
          <button
            className="border border-blue-600 rounded-md px-4 py-1 bg-blue-500 text-white hover:bg-white hover:text-blue-600 transition duration-300 ease-in-out"
            onClick={() => UploadDocument(row.original)}
          >
            Upload
          </button>
        ),
      },
    ],
    [navigate]
  );

  const table = useMaterialReactTable({
    columns,
    data: studentData || [],
    initialState: { columnVisibility: { emailAddress: false } },
  });

  return (
    <div className="w-full overflow-auto border-box">
      <MaterialReactTable table={table} />
    </div>
  );
};

export default StudentTable;
