import React from 'react'
import Sidebar from '../../Assets/Sidebar';
import Navbar from '../../Assets/Navbar';
import StudentSearchForm from '../../Assets/StudentSearchForm';
import Footer from '../../Assets/Footer';

const StudentSearch = () => {
  return (
    <div>
      <Navbar />
      <div className="flex">
        <Sidebar />
        <StudentSearchForm/>
      </div>
      <Footer/>
    </div>
  );
}

export default StudentSearch