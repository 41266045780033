import { useCallback, useContext, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

//example data type
const data = [
  {
    _id: "670cffd10951aa9df986edf6",
    centerTitle: "IGCSM",
    contactPerson: "Test",
    contactNo: "1234567890",
    emailAddress: "test@gmail.com",
    location: "New Delhi",
    __v: 0,
  },
  // Add more data objects as needed
];

const Example = () => {
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "centerTitle", 
        header: "Center Title",
        size: 150,
      },
      {
        accessorKey: "contactPerson",
        header: "Contact Person",
        size: 150,
      },
      {
        accessorKey: "contactNo", 
        header: "Contact No",
        size: 150,
      },
      {
        accessorKey: "emailAddress",
        header: "Email Address",
        size: 200,
      },
      {
        accessorKey: "location",
        header: "Location",
        size: 150,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return <MaterialReactTable table={table} />;
};

export default Example;