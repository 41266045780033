import React from 'react'
import Sidebar from '../../Assets/Sidebar';
import Navbar from '../../Assets/Navbar';
import RegistrationFromStudent from '../../Assets/RegistrationFromStudent';
import Footer from '../../Assets/Footer';

const StudentRegistration = () => {
  return (
    <div>
      <Navbar />
      <div className="flex">
        <Sidebar />
        <RegistrationFromStudent/>
      </div>
      <Footer/>
    </div>
  );
}

export default StudentRegistration