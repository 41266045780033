import React, { useContext } from 'react'
import Sidebar from '../Assets/Sidebar';
import Home from '../Assets/Home';
import Navbar from '../Assets/Navbar';
import ApiContext from '../../Context/ApiContext';

const Wallet = () => {

const { url } = useContext(ApiContext);
const token = localStorage.getItem("token");

const stream = [
  { id: 9022, skill: "Agriculture Farm House Management" },
  { id: 9023, skill: "Automotive Skills" },
  { id: 9024, skill: "Banking, Financial Services & Insurance Skills" },
  { id: 9025, skill: "E-Commerce and Digital Marketing" },
  { id: 9026, skill: "Electrical Skills" },
  { id: 9027, skill: "Entrepreneurship Skills" },
  { id: 9028, skill: "Fashion Design" },
  { id: 9029, skill: "Fire Technology and Industry Safety Management" },
  { id: 9030, skill: "Graphic Design" },
  { id: 9031, skill: "Hospitality & Hotel Management" },
  { id: 9032, skill: "Hotel Management & Tourism Skills" },
  { id: 9033, skill: "Interior Design" },
  { id: 9034, skill: "Internet of Things: Programming and Big Data" },
  { id: 9035, skill: "IT" },
  { id: 9036, skill: "Networking Skills" },
  { id: 9037, skill: "Machine Learning & AI Skills" },
  { id: 9038, skill: "Manufacturing Skills" },
  { id: 9039, skill: "Renewable Energy Technology Skills" },
  { id: 9040, skill: "Robotics and Automation" },
  { id: 9041, skill: "Yoga & Naturopathy" },
  { id: 9042, skill: "3D Animation & VFX" },
];

const handleSubmit = async () => {
  try {
    for (let index = 0; index < stream.length; index++) {
      const skill = stream[index];

      // Prepare the body for the request
      const requestBody = JSON.stringify({
        data: {
          stream_name: skill.skill, // Use the skill name as the stream_name
          course_code: skill.id.toString(), // Use the id as course_code
          course: {
            connect: ["m61efw4b8yxdcti5m1eenzzz"], // Assuming this is a static ID for the course
          },
        },
      });

      const response = await fetch(`${url}/api/streams`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ requestBody }),
      });

      // You can handle the response here if necessary
      if (!response.ok) {
        console.log(`Failed to add ${skill.skill}`);
        throw new Error("Failed to create stream");
      }
      alert(`Successfully added ${skill.skill}`);
    }
  } catch (error) {
    console.error(error);
  }
};



  return (
    <div>
      <Navbar />
      <div className="flex">
        <Sidebar />
        <div className="bg-red-50 w-full items-center justify-center">
          <button onClick={handleSubmit} className='m-4 p-2 text-red-500 bg-red-200 rounded border-red-600 border hover:border-red-200'>Click me</button>
        </div>
      </div>
    </div>
  );
}

export default Wallet