import React from "react";
import StudentTable from "./Table/StudentTable";

const StudentSearchForm = () => {
  return (
    <>
      <div className="w-full bg-gray-200 box-border">
        <div className="m-4 bg-white p-2  rounded-lg shadow-sm">
          {/* <form>
           
            <div className="block p-4 m-2 bg-blue-200 rounded-lg font-bold text-blue-600 border border-blue-400">
              Search Students
            </div>
            <div className="flex mx-4 py-2">
              <div className="w-1/4">
                <label
                  htmlFor="schoolcollegename"
                  className="p-1 font-semibold"
                >
                  School/College Name
                </label>
                <select
                  id="schoolcollegename"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded text-sm"
                >
                  <option value="" disabled selected>
                    Select Session
                  </option>
                  <option value="school_of_agriculture">
                    School of Agriculture
                  </option>
                  <option value="school_of_arts_humanities_and_social_science">
                    School of Arts, Humanities and Social Science
                  </option>
                </select>
              </div>
              <div className="w-1/4">
                <label htmlFor="department_name" className="p-1 font-semibold">
                  Department Name
                </label>
                <select
                  id="department_name"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                >
                  <option value="" disabled selected>
                    Select Department
                  </option>
                  <option value="departmentofarts">Departments of Arts</option>
                </select>
              </div>
              <div className="w-1/4">
                <label htmlFor="coursename" className="p-1 font-semibold">
                  Course Name
                </label>
                <select
                  id="coursename"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                >
                  <option value="" disabled selected>
                    Select Course
                  </option>
                  <option value="course"></option>
                </select>
              </div>
              <div className="w-1/4">
                <label htmlFor="streamname" className="p-1 font-semibold">
                  Stream Name
                </label>
                <select
                  id="streamname"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                >
                  <option value="" disabled selected>
                    Select Stream
                  </option>
                  <option value="stream"></option>
                </select>
              </div>
            </div>
            <div className="flex mx-4 py-2">
              <div className="w-1/4">
                <label htmlFor="session" className="p-1 font-semibold">
                  Session
                </label>
                <select
                  id="session"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                >
                  <option value="" disabled selected>
                    Select Session
                  </option>
                  <option value="2023-24">2023-24</option>
                  <option value="2024-25">2024-25</option>
                </select>
              </div>
              <div className="w-1/4">
                <label htmlFor="year" className="p-1 font-semibold">
                  Year
                </label>
                <select
                  id="year"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                >
                  <option value="" disabled selected>
                    Select Year
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="w-1/4">
                <label
                  htmlFor="registration_status"
                  className="p-1 font-semibold"
                >
                  Registration Status
                </label>
                <select
                  id="registration_status"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded text-sm"
                >
                  <option value="" disabled selected>
                    Select Registration Status
                  </option>
                  <option value="Un-enrolled">Un-enrolled</option>
                  <option value="enrolled">Enrolled</option>
                  <option value="re-registered">Re-Registered</option>
                  <option value="passout">Passout</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>
              <div className="w-1/4">
                <label htmlFor="reference" className="p-1 font-semibold">
                  Reference
                </label>
                <select
                  id="reference"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                >
                  <option value="" selected></option>
                </select>
              </div>
            </div>
            <div className="flex mx-4 py-2">
              <div className="w-1/4">
                <label htmlFor="studentname" className="p-1 font-semibold">
                  Student Name
                </label>
                <input
                  type="text"
                  id="studentname"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                />
              </div>
              <div className="w-1/4">
                <label htmlFor="fathername" className="p-1 font-semibold">
                  Father Name
                </label>
                <input
                  type="text"
                  id="fathername"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                />
              </div>
              <div className="w-1/4">
                <label htmlFor="studentid" className="p-1 font-semibold">
                  Student Id
                </label>
                <input
                  type="text"
                  id="studentid"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                />
              </div>
              <div className="w-1/4">
                <label htmlFor="enrollmentno" className="p-1 font-semibold">
                  Enrollment No
                </label>
                <input
                  type="text"
                  id="enrollmentno"
                  className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                />
              </div>
            </div>
            <div className="text-center mb -4 my-2">
              <button className="bg-blue-500 text-white font-semibold p-2 px-4 mt-6 rounded">
                Search Button
              </button>
            </div>
          </form> */}
          <div className="container border-box w-full overflow-hidden">
            <StudentTable className="mx-4 mt-8 w-full max-w-screen-lg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentSearchForm;
