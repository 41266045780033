import React, { useContext, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ApiContext from "../../../Context/ApiContext";

const RegistrationForm = () => {
  const { url, studentFromData } = useContext(ApiContext);
  const contentRef = useRef();


  // Define handlePrint directly with useReactToPrint
  const reactToPrintFn = useReactToPrint({ contentRef });
  if (!studentFromData) {
    return <p>No studentFromData data available to print.</p>;
  }
  // setTimeout(() => {
  //   console.log(studentFromData.photo)
  // }, 1000);
  
  return (
    <>
      <div className="mx-auto w-full flex items-center justify-center border">
        <style type="text/css">
          {`
            html,body{
                margin: 0px;
            }
            table.headerTable{
                font-family: "Arial";
                border-width: 0px;
                border-collapse: collapse;
            }
            table.headerTable td{
                font-size: 12px;
                color: #333333;
                font-weight: bold;
            }
            table.headerTable td.other{
                font-size: 12px;
                color: #333333;
                font-weight: bold;
            }
            table.headerTable td.colhead{
                color: #1589ff;
                font-weight:  bold;
                font-size: 38px;
            }
            table.headerTable td.orange{
                color: #ff8800;
                font-size: 12px;
                font-weight: bold;
                
            }
            table.headerTable td.brown{
                color: #993300;
                font-size: 12px;
                font-weight: bold;
                
            }
            span.form{
                font-family: "Arial";
            	font-weight: bold;
            	font-size: 16px;
            	color: #333333;
                background: #f8f8f8;
                border: 1px solid #cccccc;
                padding: 5px;
            }
            p.dec{
                font-family: "Arial";
            	font-weight: normal;
            	font-size: 12px;
            	color: #000000;
                text-align: justify;
            }
            table.detailTable{
            	font-family: "Arial";
                border-collapse: collapse;
            }
            table.detailTable td{
            	font-weight: bold;
            	font-size: 12px;
            	color: #000000;
            }
            table.detailTable td.up{
            	font-size: 12px;
            	color: #000000;
            }
            table.detailTable td.heading{
            
            	font-weight: bold;
            	font-size: 14px;
            	color: #1589ff;
                background: #f8f8f8;
                border: 1px solid #cccccc;
                padding: 5px;
            }
            table.detailTable td.heading1{
            
            	font-weight: bold;
            	font-size: 12px;
            	color: #333333;
            }
            table.detailTable td.heading2{
            
            	font-weight: bold;
            	font-size: 12px;
            	color: #333333;
                text-decoration: underline;
            }
            table.detailTable td.data{
            
            	font-weight: bold;
            	font-size: 12px;
            	color: #000000;
                text-transform: uppercase;
                border-bottom: 1px dotted #cccccc;
            }
            table.detailTable td.data1{
            
            	font-weight: bold;
            	font-size: 12px;
            	color: #000000;
                border-bottom: 1px dotted #cccccc;
            }
            table.examTable{
            	font-family: "Arial";
                border-collapse: collapse;
                border: 1px solid #cccccc;
            }
            table.examTable td{
            	font-weight: bold;
            	font-size: 12px;
            	color: #000000;
                border: 1px solid #cccccc;
            }
            table.examTable td.heading{
            
            	font-weight: bold;
            	font-size: 14px;
            	color: #1589ff;
                background: #f8f8f8;
                border: 1px solid #cccccc;
                padding: 5px;
            }
            table.examTable td.heading1{
            
            	font-weight: bold;
            	font-size: 12px;
            	color: #333333;
            }
            table.examTable td.data{
            
            	font-weight: bold;
            	font-size: 12px;
            	color: #000000;
                text-transform: uppercase;
            }
          `}
        </style>

        <div ref={contentRef}>
          <table className="detailTable pl-4" width="700" cellPadding="6">
            <tbody>
              <tr>
                <td colSpan="3" style={{ borderBottom: "2px solid #cccccc" }}>
                  <table
                    className="headerTable"
                    width="100%"
                    cellPadding="2"
                    align="center"
                  >
                    <tbody>
                      <tr>
                        <td align="right" rowSpan="2">
                          {/* <img
                            src="http://localhost:1337/uploads/logo_9fd9c29549.png"
                            width="72"
                            height="72"
                            alt="Logo"
                          /> */}
                        </td>
                        <td className="colhead" align="center">
                          <img
                            src={`${url}/uploads/sikkim_global_logo_3_0e82dcc859.svg`}
                            width="400"
                            height="200"
                            alt="Sikkim Global Technical University"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td align="center" className="other">
                          Rong, Namchi District, Sikkim, India - 737126
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <table width="100%" align="center">
                            <tr>
                              <td className="other">Phone:</td>
                              <td className="orange">(+91) 9891100043</td>
                              <td className="other">Website:</td>
                              <td className="brown">www.sgtu.ac.in</td>
                              <td className="other">Email:</td>
                              <td className="brown">info@sgtu.ac.in</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td width="150px">&nbsp;</td>
                <td align="center">
                  <span className="form">ADMISSION FORM</span>
                </td>
                <td rowSpan="4" align="right" width="80px">
                  <img
                    src={`${url}${studentFromData.photo.url}`}
                    alt="Candidate"
                    style={{
                      imageOrientation: "from-image",
                      border: "1px dotted #333333",
                      height: "84px",
                      weight: "84px",
                    }}
                    width="80"
                    height="100"
                  />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td align="center" className="up">
                  SESSION:&nbsp;&nbsp;&nbsp;
                  {studentFromData.session && studentFromData.session.Session}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td align="center" className="up">
                  COURSE:&nbsp;&nbsp;&nbsp;
                  {studentFromData.course && studentFromData.course.course_name}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td align="center" className="up">
                  STREAM:&nbsp;&nbsp;&nbsp;
                  {studentFromData.stream && studentFromData.stream.stream_name}
                </td>
              </tr>
              <tr>
                <td className="heading" align="center" colSpan="3">
                  GENERAL INFORMATION
                </td>
              </tr>
            </tbody>
          </table>
          <table className="detailTable" width="700" cellPadding="6">
            <tbody>
              <tr>
                <td colSpan="2">
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="160px">
                        Enrollment No:
                      </td>
                      <td className="data" width="170px">
                        SGTU1223
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="160px">
                        Name of the Candidate:
                      </td>
                      <td className="data">
                        {studentFromData.studentFromDataName &&
                          studentFromData.studentFromDataName}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="160px">
                        Father's Name:
                      </td>
                      <td className="data" width="170px">
                        {studentFromData.fatherName &&
                          studentFromData.fatherName}
                      </td>
                      <td className="heading1" width="160px">
                        Mother Name:
                      </td>
                      <td className="data" width="170px">
                        {studentFromData.motherName &&
                          studentFromData.motherName}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="160px">
                        Date Of Birth:
                      </td>
                      <td className="data" width="170px">
                        {studentFromData.dateOfBirth &&
                          studentFromData.dateOfBirth}
                      </td>
                      <td className="heading1" width="160px">
                        Nationality:
                      </td>
                      <td className="data" width="170px">
                        {studentFromData.nationality &&
                          studentFromData.nationality}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="160px">
                        Aadhaar No:
                      </td>
                      <td className="data" width="170px">
                        {studentFromData.aadhaarNumber &&
                          studentFromData.aadhaarNumber}
                      </td>
                      <td className="heading1" width="160px">
                        Passport No:
                      </td>
                      <td className="data" width="170px">
                        {studentFromData.passportNo &&
                          studentFromData.passportNo}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="160px">
                        Category:
                      </td>
                      <td className="data" width="170px">
                        {studentFromData.category && studentFromData.category}
                      </td>
                      <td className="heading1" width="160px">
                        Gender:
                      </td>
                      <td className="data" width="170px">
                        {studentFromData.gender && studentFromData.gender}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="160px">
                        Admission Type:
                      </td>
                      <td className="data" width="170px">
                        {studentFromData.admissionType &&
                          studentFromData.admissionType}
                      </td>
                      <td className="heading1" width="160px">
                        Year:
                      </td>
                      <td className="data" width="170px">
                        1
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="160px">
                        Contact Number:
                      </td>
                      <td className="data" width="170px">
                        {studentFromData.contactNumber &&
                          studentFromData.contactNumber}
                      </td>
                      <td className="heading1" width="160px">
                        Email Address:
                      </td>
                      <td className="data" width="170px">
                        {studentFromData.emailAddress &&
                          studentFromData.emailAddress}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td className="heading1" width="155px">
                  Candidate Address:
                </td>
                <td className="data">
                  {studentFromData.address && studentFromData.address}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="100px">
                        District:
                      </td>
                      <td className="data" width="150px">
                        {studentFromData.district && studentFromData.district}
                      </td>
                      <td className="heading1" width="100px">
                        Pin Code:
                      </td>
                      <td className="data" width="150px">
                        {studentFromData.pinCode && studentFromData.pinCode}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="100px">
                        State:
                      </td>
                      <td className="data" width="150px">
                        {studentFromData.state && studentFromData.state}
                      </td>
                      <td className="heading1" width="100px">
                        Country:
                      </td>
                      <td className="data" width="150px">
                        {studentFromData.country && studentFromData.country}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td className="heading" colSpan="2">
                  Academic Information
                </td>
              </tr>
              <tr>
                <td className="heading1" colSpan="2" align="center">
                  Previous Education
                </td>
              </tr>
              <tr>
                <td className="heading1">Board/University:</td>
                <td className="data">
                  {studentFromData.examSrSecondaryBoardUniversity &&
                    studentFromData.examSrSecondaryBoardUniversity}
                </td>
              </tr>
              <tr>
                <td className="heading1">Class:</td>
                <td className="data">12th</td>
              </tr>
              <tr>
                <td className="heading1">Year of Passing:</td>
                <td className="data">
                  {studentFromData.examSrSecondaryYearOfPassing &&
                    studentFromData.examSrSecondaryYearOfPassing}
                </td>
              </tr>
              <tr>
                <td className="heading1">Percentage:</td>
                <td className="data">
                  {studentFromData.examSrSecondaryPercentage &&
                    studentFromData.examSrSecondaryPercentage}
                </td>
              </tr>
              <tr>
                <td className="heading1">Subjects:</td>
                <td className="data">
                  {studentFromData.SrSecondarySubjects &&
                    studentFromData.SrSecondarySubjects}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="160px">
                        Any Other Qualification:
                      </td>
                      <td className="data" width="170px"></td>
                      <td className="heading1" width="160px">
                        Medium of Instruction:
                      </td>
                      <td className="data" width="170px">
                        English
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td className="heading" colSpan="2">
                  Signature
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="160px">
                        Signature of the Candidate:
                      </td>
                      <td className="data" width="170px"></td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table width="100%">
                    <tr>
                      <td className="heading1" width="160px">
                        Signature of Parent/Guardian:
                      </td>
                      <td className="data" width="170px"></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
          className="absolute top-4 right-10 font-semibold border px-6 py-2 bg-blue-500 hover:bg-blue-600 hover:underline text-white rounded-lg"
          onClick={reactToPrintFn}
        >
          Print
        </button>
      </div>
    </>
  );
};

export default RegistrationForm;
