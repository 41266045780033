import React from "react";
import Sidebar from "../../Assets/Sidebar";
import Navbar from "../../Assets/Navbar";
import Footer from "../../Assets/Footer";
import DocumentUploadFrom from "../../Assets/DocumentUploadFrom";

const UploadDocument = () => {
  return (
    <div>
      <Navbar />
      <div className="flex">
        <Sidebar />
        <DocumentUploadFrom/>
      </div>
      <Footer />
    </div>
  );
};

export default UploadDocument;
